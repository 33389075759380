<template>
    <div class="bg-white shadow-lg rounded-lg mr-3 ml-3 pb-5 mt-5 relative">
        <LoadingComponent :isLoading="loading" />
        <div class="content">
            <div class="top-content">
                <div class="title text-black-blod-18">猜你喜歡資訊</div>
                <div class="inputs">
                    <div class="input">
                        <div class="profile" @click="goProfile">
                            <img class="profile-img" :src="inputs.avatar" alt="" />
                            <div class="name">
                                {{ inputs.name }}
                            </div>
                        </div>
                    </div>
                    <div class="input">
                        <div class="label">啟用狀態</div>
                        <el-select v-model="inputs.activated" class="w-full" placeholder="請選擇">
                            <el-option v-for="(item, idx) in openOption" :key="idx" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">使用期間</div>
                        <el-select v-model="inputs.duration" class="w-full" placeholder="請選擇">
                            <el-option v-for="(item, idx) in duringOption" :key="idx" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </div>
                    <div class="input">
                        <div class="label">期間範圍</div>
                        <el-date-picker
                            v-model="inputDate"
                            :disabled="inputs.duration == false"
                            style="width: 100%"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="開始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="black-btn-outline-lg text-md mr-5" @click="goBackList">返回列表</button>
            <button v-permission="['create']" class="orange-btn-800-lg text-md" @click="create">儲存變更</button>
        </div>
    </div>
</template>

<script>
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
export default {
    name: "AddComer",
    components: {
        LoadingComponent,
    },
    computed: {
        // 啟用選項
        openOption() {
            return [
                { label: "啟用", value: true },
                { label: "停用", value: false },
            ];
        },
        // 使用期間選項
        duringOption() {
            return [
                { label: "不限使用期間", value: false },
                { label: "限定使用期間", value: true },
            ];
        },
    },
    data() {
        return {
            loading: true,
            showDialog: true,
            // 輸入匡狀態
            inputs: {
                id: 0,
                avatar: "",
                name: "",
                start: null,
                end: null,
                activated: true,
                duration: false,
            },
            // 額外處理輸入
            inputDate: [],
        };
    },
    methods: {
        // 新增 API
        create() {
            const { id, duration, end, start, activated } = this.inputs;
            if (duration) {
                if (!start || !end) {
                    alert("使用期間未填寫完整");
                    return;
                }
            }

            const req = {
                user_id: id,
                duration,
                activated,
            };

            if (duration) {
                req.start = start;
                req.end = end;
            }

            this.$api
                .CreatNewbies(req)
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "新增成功",
                    });
                    this.$router.push({
                        path: `/homepage_setting/newcomer/edit/${id}`,
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "新增失敗",
                    });
                });
        },
        // 返回列表
        goBack() {
            this.$router.push({ path: "/homepage_setting/newcomer_list" });
        },
        // 前往服務商頁面
        goProfile() {
            window.open(`/#/user_list/provider_update/${this.inputs.id}`);
        },
        // 取得此使用資訊
        getData(data) {
            const { name, id, avatar } = data;
            this.inputs = { ...this.inputs, avatar, name, id };
            this.loading = false;
        },
        // 返回列表
        goBackList() {
            this.$router.push({ name: "newcomer_list" });
        },
    },
    watch: {
        // 監聽 element-ui 日期輸入匡，將值設定於輸入匡變數中
        inputDate(val) {
            if (val === null || val.length === 0) {
                this.inputs.start = "";
                this.inputs.end = "";
                return;
            }
            this.inputs.start = val[0];
            this.inputs.end = val[1];
        },
    },
    async mounted() {
        const { status, data } = await this.$api
            .UserSearchByPhone(this.$route.params.phone)
            .then((res) => {
                return { status: res.status, data: res.data };
            })
            .catch(() => {
                return { status: 400, data: { message: "err" } };
            })
            .finally(() => {
                this.loading = false;
            });

        if (status === 200) {
            this.getData(data);
        } else {
            alert("請確認手機號碼");
            this.goBack();
        }
    },
};
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
}
.content {
    .title {
        padding-left: 20px;
    }
    .top-content {
        padding: 30px 5% 0;
        .inputs {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 20px 20px 30px;
            .input {
                width: 48%;
                padding: 10px 0px;
                .profile {
                    display: flex;
                    align-items: center;
                    .profile-img {
                        width: 60px;
                        height: 60px;
                        border-radius: 50%;
                    }
                    .name {
                        color: #ff5733;
                        text-decoration: underline;
                        font-size: 14px;
                        cursor: pointer;
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .bottom-content {
        padding: 30px 5%;
        .upload-area {
            display: flex;
            padding: 20px 20px 30px;
            .left {
                max-width: 50%;
                flex: 1;
                margin-right: 30px;
            }
            .right {
                flex: 1;
            }
        }
    }
    .label {
        color: rgb(133, 130, 130);
        margin-bottom: 5px;
        font-size: 14px;
    }
}

.display-img {
    margin-top: 15px;
    position: relative;
    padding: 30px;
    .error {
        width: 24px;
        height: 24px;
        background: #000;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}
::v-deep .el-upload-list {
    display: none;
}
.actions {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>
